import { ActionCreator } from 'typescript-fsa';

import {
  CUSTOM_OBJECT_TABLE_MODAL_SCHEME,
  FORMULA_MODAL_SCHEME,
  REV_BI_ACCOUNT_MODAL_SCHEME,
  REV_BI_DEALS_MODAL_SCHEME,
} from 'components/dashboard/Metrics/Widget/hooks/useRevBiDrilldownModal';

export type AppStateRecord = {
  metrics: {
    expanded: boolean;
  };
  sizeColumns: {
    width: string;
    id: string;
  }[];
  showOrHideColumns: {
    label: string;
    id: string;
    show: boolean;
  }[];
  sequenceColumns: string[];
};

export type URLScheme<T> = {
  scheme: T;
  params?: { [key: string]: string | number };
};

export type ModalPersistParams = {
  [key: string]: any;
};

export type ModalURLProps = URLScheme<ModalsScheme> & {
  persistParams?: ModalPersistParams;
  modalOptions?: ModalPersistParams;
};

export type ModalRouteProps<
  Params extends { [K in keyof Params]?: string | number } = {}
> = {
  params: Params;
  onClose?(): void;
};

export type ModalPersistedProps<
  Params extends { [K in keyof Params]?: any } = {}
> = Params;

export type PersistQueryParams = {
  scheme: ModalsScheme;
  persistParams: ModalPersistParams;
  modalOptions?: ModalPersistParams;
};

export type PersistorFunction = (params: PersistQueryParams) => void;

export type OpenModalProps = URLScheme<ModalsScheme> & {
  onClose?: () => void;
} & (
    | {
        persistParams: ModalPersistParams;
        persistor: ActionCreator<PersistQueryParams> | PersistorFunction;
      }
    | {}
  );

export type IUserProfileNavigationItem = {
  enabled: boolean;
  name: string;
  title: string;
  sub_nav?: IUserProfileNavigationItem[];
  default_landing?: string;
  locked?: boolean;
  type: 'default' | 'revbi_dashboard';
  revbi_dashboards_enabled?: boolean;
};

export type INavigationScheme = {
  [key: string]: {
    scheme: string;
    title: string;
  };
};

export type INavigationSchemes = {
  [key: string]:
    | INavigationScheme
    | {
        scheme: string;
        title: string;
      };
};

export const MODALS_SCHEMES = [
  '/account/:id',
  '/accounts',
  '/activity/:activityId',
  '/activity/personal/:email',
  '/settings/data-integration/edit',
  '/business-type/add',
  '/business-type/edit/:businessType',
  '/call/:id',
  '/deals-status/:tab',
  '/import-targets/:interval/:year/:metric/:businessType',
  '/import-targets/:interval/:year/:metric',
  '/deal/:id',
  '/deals',
  '/deals/:tab',
  '/deals/included/:submissionId',
  '/edit-profile/:profileId',
  '/email/:emailId',
  '/emails',
  '/event/:eventId',
  '/events',
  '/next-step/:nextStepId',
  '/thena-requests/:thenaRequestsId',
  '/user/:id',
  '/users/add',
  '/users/add/manually',
  '/crm-table/:tableName/filters',
  '/historical-drilldown',
  '/opportunity-split',
  '/funnel-drilldown',
  '/fullscreen-trends',
  '/follow-up-email',
  '/sales-process',
  CUSTOM_OBJECT_TABLE_MODAL_SCHEME,
  REV_BI_DEALS_MODAL_SCHEME,
  REV_BI_ACCOUNT_MODAL_SCHEME,
  FORMULA_MODAL_SCHEME,
] as const;

// Union type that contains all possible modal schemes, same as doing 'a' | 'b' | 'c' | ...
// Doing it this way so we have all the schemes values in ALL_MODALS_SCHEMES
export type ModalsScheme = (typeof MODALS_SCHEMES)[number];

export type Scheme =
  | '/login'
  | '/sign-up'
  | '/privacy'
  | '/tos'
  | '/error'
  | '/sfobject/opportunity/:dealId'
  | '/admin/dashboard'
  | '/meetings'
  | '/meetings/completed'
  | '/event/:eventId'
  | '/search'
  | '/settings'
  | '/pipeline/analytics'
  | '/pipeline/dashboard'
  | '/pipeline/deals-progression'
  | '/dashboards'
  | '/dashboards/0'
  | '/dashboards/1'
  | '/dashboards/2'
  | '/opportunities/preview/:dealId'
  | '/accounts/all'
  | '/accounts/recommendations'
  | '/accounts/analytics'
  | '/accounts/preview/:id'
  | '/sfobject/account/:id'
  | '/opportunities'
  | '/forecast/analytics'
  | '/calls'
  | '/calls/upcoming'
  | '/transcript/:id'
  | '/external/call/:id'
  | '/targets/forecast'
  | '/targets/pipeline-creation'
  | '/targets/pipeline-coverage'
  | '/forecast/pacing'
  | '/forecast/dashboard'
  | '/forecast/roll-ups/preview/:tableId'
  | '/forecast/roll-ups'
  | '/prospect-account-dashboard'
  | '/reps-recommendations/:tableName?'
  | '/scorecard'
  | '/dashboard'
  | '/dashboard/forecast'
  | '/dashboard/trends'
  | '/dashboard/deals-progression'
  | '/dashboard/pacing'
  | '/dashboard/pipeline'
  | '/dashboard/risk_analytics'
  | '/dashboard/leaderboard'
  | '/dashboard/scorecard'
  | '/dashboard/seller'
  | '/revbi'
  | '/revbi/metrics/list'
  | '/revbi/dashboard/:dashboardId'
  | '/third-party-integration/create'
  | '/revbi/metrics/create'
  | '/revbi/metrics/edit/:metricId'
  | '/revbi/widgets/edit/:widgetId'
  | '/revbi/dashboards/list'
  | '/revbi/widgets/list'
  | '/revbi/widgets/create'
  | '/:tab_name/rev-bi/dashboard/:id';
