import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

import { BoostUpIcons } from 'assets/css/boostup-icons';
import { useModal } from 'components/modals/ModalContext/modal.context';
import BuIcon from 'components/UI/BuIcon';
import {
  MeddicField,
  StepWithValue,
} from 'components/dashboard/Forecast/MeddicSidePanel/MeddicSidePanel';
import { ValueType } from 'components/UI/common/TypedTable/TypedTable';
import BuButton from 'components/UI/BuButton';
import { getUserLocalCurrency } from 'selectors/settings';
import { putSalesProcess } from 'api/CallInsights';

import * as styles from './styles';
import { SalesProcessModal as SalesProcessModalType, Step } from '../../types';

export const SalesProcessModalTitle: React.FC = () => {
  const { getPropsForScheme } = useModal<SalesProcessModalType>();

  const {
    salesProcessItem: {
      task: { name = '' },
    },
  } = getPropsForScheme('/sales-process') || {};

  return <div className={styles.salesProcessModalTitle}>Update {name}</div>;
};

const SalesProcessModal: React.FC = () => {
  const [updatedValues, setUpdatedValues] = useState<Step[]>([]);
  const [isSaving, setIsSaving] = useState(false);
  const { getPropsForScheme, closeModal } = useModal<SalesProcessModalType>();
  const companyCurrency = useSelector(getUserLocalCurrency);

  const {
    salesProcessItem: {
      task: { name = '', opportunity_id = '', steps: initialSteps = [] },
    },
    triggerCallTodoData,
  } = getPropsForScheme('/sales-process') || {};

  useEffect(() => {
    setUpdatedValues(initialSteps);
  }, [initialSteps]);

  const changeSalesProcessMutation = useMutation({
    mutationFn: (steps: Record<string, Step['new_value']>) =>
      putSalesProcess({
        opportunity_id,
        sales_process: name,
        steps,
      }),
    onMutate: () => {
      setIsSaving(true);
      toast.warn('Saving sales process...', { position: 'bottom-left' });
    },
    onError: () => {
      setIsSaving(false);
      toast.error('Error saving sales process.', { position: 'bottom-left' });
    },
    onSuccess: () => {
      setIsSaving(false);
      toast.success('Sales process saved successfully.', {
        position: 'bottom-left',
      });
      closeModal('/sales-process', () => ({}));
      triggerCallTodoData();
    },
  });

  const handleFieldChange = (step: Step) => (value: ValueType) => {
    setUpdatedValues(
      updatedValues.map((item) =>
        item.step_object_field === step.step_object_field
          ? { ...step, new_value: value }
          : item
      )
    );
  };

  const changeSalesProcess = () => {
    const stepsPayload = updatedValues.reduce(
      (acc: Record<string, Step['new_value']>, step: Step) => {
        acc[step.field_info.step_name] = step.new_value;
        return acc;
      },
      {} as Record<string, Step['new_value']>
    );

    changeSalesProcessMutation.mutate(stepsPayload);
  };

  // Planned feature, will have this in the future
  // const handleExplanationClicked = (step: Step) => {
  //   const originalNewValue = initialSteps.find(
  //     (item) => item.step_object_field === step.step_object_field
  //   )?.new_value;

  //   handleFieldChange(step)(originalNewValue as ValueType);
  // };

  return (
    <div className={styles.salesProcessModalWrapper}>
      <div className="sales-process-modal-title">
        Suggested {name} Updates. Do you want to update it?
      </div>

      <div className="sales-process-modal-content">
        <div className="left-side">
          <div className="left-side-title">Existing values</div>

          <div className="left-side-fields">
            {initialSteps.map((step) => (
              <MeddicField
                key={step.step_object_field}
                readOnly
                step={
                  {
                    ...step.field_info,
                    editable: false,
                    value: step.current_value,
                  } as StepWithValue
                }
                companyCurrency={companyCurrency}
              />
            ))}
          </div>
        </div>

        <div className="middle-arrow">
          <BuIcon name={BoostUpIcons.ChevronRight} />
        </div>

        <div className="right-side">
          <div className="right-side-title">New values</div>

          <div className="right-side-fields">
            {updatedValues.map((step) => (
              <MeddicField
                key={step.step_object_field}
                readOnly={false}
                step={
                  {
                    ...step.field_info,
                    explanation: step.explanation,
                    value: step.new_value,
                  } as StepWithValue
                }
                onChange={handleFieldChange(step)}
                companyCurrency={companyCurrency}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="sales-process-modal-footer">
        <BuButton
          secondary
          disabled={isSaving}
          onClick={() => closeModal('/sales-process', () => ({}))}
        >
          Cancel
        </BuButton>

        <BuButton onClick={changeSalesProcess} disabled={isSaving}>
          Submit
        </BuButton>
      </div>
    </div>
  );
};

export default SalesProcessModal;
