import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';

import BuIcon from 'components/UI/BuIcon';
import { BoostUpIcons } from 'assets/css/boostup-icons';
import BuButton from 'components/UI/BuButton';
import { useModal } from 'components/modals/ModalContext/modal.context';

import * as styles from './styles';
import {
  CallInsightsResponse,
  CallTodoResponse,
  FollowUpEmailModal,
  SalesProcessModal,
} from '../../types';

type Props = {
  followUpEmailData: CallInsightsResponse['follow_up_email_data'];
  salesProcess: CallTodoResponse[] | null;
  triggerCallTodoData: () => void;
};

const ActionItemsTab: React.FC<Props> = ({
  followUpEmailData,
  salesProcess,
  triggerCallTodoData,
}) => {
  const { openModal: openFollowUpEmailModal } = useModal<FollowUpEmailModal>();
  const { openModal: openSalesProcessModal } = useModal<SalesProcessModal>();

  const handleSendFollowUpClick = () => {
    openFollowUpEmailModal({
      scheme: '/follow-up-email',
      props: followUpEmailData,
    });
  };

  const handleUpdateSalesProcessClick = (
    salesProcessItem: CallTodoResponse
  ) => {
    openSalesProcessModal({
      scheme: '/sales-process',
      props: {
        salesProcessItem,
        triggerCallTodoData,
      },
    });
  };

  const hasActionItems =
    followUpEmailData?.body || (salesProcess?.length || 0) > 0;

  return (
    <div className={styles.tabContentWrapper}>
      <div className={styles.actionItemsTabWrapper}>
        {hasActionItems ? (
          <>
            {followUpEmailData?.body && (
              <div className="action-items-card">
                <div className="content">
                  <div className="content-description">
                    {/* TODO: Will have this in the future */}
                    {/* <div className="done-wrapper">
                      <div className="done-icon">
                        <BuIcon name={BoostUpIcons.Checked} />
                      </div>
                    </div> */}

                    <BuIcon
                      name={BoostUpIcons.Mail}
                      className="follow-up-email-icon"
                    />

                    <span>Generate follow up email for this meeting</span>
                  </div>

                  <div className="content-actions">
                    <BuButton onClick={handleSendFollowUpClick} secondary>
                      Send Follow Up
                    </BuButton>
                  </div>
                </div>
              </div>
            )}

            {!!salesProcess &&
              (salesProcess.length > 0 ? (
                salesProcess.map((salesProcessItem) => (
                  <div
                    className="action-items-card"
                    key={salesProcessItem.task.name}
                  >
                    <div className="content">
                      <div className="content-description">
                        <BuIcon
                          name={BoostUpIcons.Money}
                          className="sales-process-icon"
                        />

                        <span>
                          Update {salesProcessItem.task.name} of the opportunity
                          based on the meeting discussion and insights
                        </span>
                      </div>

                      <div className="content-actions">
                        <BuButton
                          onClick={() =>
                            handleUpdateSalesProcessClick(salesProcessItem)
                          }
                          secondary
                        >
                          Update {salesProcessItem.task.name}
                        </BuButton>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <Dimmer inverted active>
                  <Loader />
                </Dimmer>
              ))}
          </>
        ) : (
          <div className={styles.notAvilableContent}>
            No action items available
          </div>
        )}
      </div>
    </div>
  );
};

export default ActionItemsTab;
